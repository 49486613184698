<template>
  <div class="custome-calculator-wrapper d-flex align-items-center justify-content-center">
    <div class="custome-calculator">
      <div class="bgWhite">
        <h4 style="border:1px solid #ccc" class="px-2 py-1 mb-0">利率計算機</h4>
      </div>
      <div style="background:#CCC" class="p-2">
        <div class="mb-2">
          <v-select
            v-model="calculateData.is_monthly"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="loan_is_monthly"
            :clearable="false"
            label="name"
            :reduce="(option) => option.id"
            placeholder="輸入供款週期"
          />
        </div>
        <div class="mb-2">
          <b-input-group>
            <b-input-group-append>
              <feather-icon size="20" icon="DollarSignIcon" />
            </b-input-group-append>
            <b-form-input
                type="number"
              placeholder="貸款金額"
              v-model="calculateData.amount"
              class=""
            />
          </b-input-group>
        </div>
        <div class="row mb-2">
          <div class="col-6">
            <b-input-group>
              <b-input-group-append>
                <feather-icon size="20" icon="ListIcon" />
              </b-input-group-append>
              <b-form-input
                type="number"
                placeholder="期數"
                v-model="calculateData.period"
                class=""
              />
            </b-input-group>
          </div>
          <div class="col-6">
            <b-input-group>
              <b-input-group-append>
                <feather-icon size="20" icon="PercentIcon" />
              </b-input-group-append>
              <b-form-input
                type="number"
                placeholder="利率"
                v-model="calculateData.annual_interest_rate"
                class=""
              />
            </b-input-group>
          </div>
        </div>
        <div class="text-center mb-2">
          <h4 :class="`${isError ? 'wrong' : 'corrent'} mb-0`">{{result}}</h4>
          <div style="font-size:12px">* 供款金額僅作參考。</div>
        </div>
        <div class="row">
          <div class="col-6">
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="danger"
              @click="resetCalculateData"
              class="w-100"
            >
              AC
            </b-button>
          </div>
          <div class="col-6">
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="submit"
              variant="primary"
              @click="calculateLoan"
              class="w-100"
            >
              計算
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BButton,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BButton,
    vSelect,
  },
  directives: {
    Ripple,
  },
  methods: {
    resetCalculateData(){
        this.calculateData = {
            amount:null,
            is_monthly:null,
            period:null,
            annual_interest_rate:null
        }
    },
    Cal(calPrecent, is_monthly , calAmount, calYear){

					var a = calAmount;
					var n = calYear;
					var i = calPrecent;
					var sum = 0;

					if ( isNaN(n) == true ) sum = 0;

					i=i/(is_monthly ? 12 : 24);
          console.log('i',i)
					var ti=i/100;
					ti=1+ti
					var tti=ti;

					for (var loopi=1; loopi<n; loopi++)
						tti=tti*ti;

					tti=1/tti;
					tti=1-tti;
					ti=i/100;
					tti=ti/tti;
					tti=a*tti;
					ti=tti;

					if ( ti == tti.toFixed(0) )
						sum = ti;
					else if( isNaN(ti.toFixed(0)))
						sum = 0;
					sum = ti.toFixed(0);

					if(a && n && i){
						return sum;
					} else {
						return false;
					}
			
		},
    calculateLoan(){
        const {amount,is_monthly,period,annual_interest_rate} = this.calculateData
        if(amount || is_monthly || period || annual_interest_rate){
            const result = this.Cal(Number(annual_interest_rate), Number(is_monthly), Number(amount), Number(period))
            this.result = "供款金額 : HK$" + result
            this.isError = false
        }else{
            this.result = "請填妥表格！"
            this.isError = true
        }
    }
  },
  data() {
    return {
      calculateData: {
        amount:null,
        is_monthly:null,
        period:null,
        annual_interest_rate:null
      },
      result:"供款金額 : HK$",
      isError:false,
      loan_is_monthly: [
        { id: 1, name: "月供" },
        { id: 0, name: "半月供" },
      ],
    };
  },
  setup() {},
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.custome-calculator-wrapper{
  height:60vh;
}
.custome-calculator {
    width:500px;
    .v-select input{
        height:28px;
    }
    input{
        border:0px;
    }
    .wrong{
        color:red;
    }
  .input-group-append {
    background: #fff;
    margin-left: 0px;
    width: 38px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right:1px solid #d4d4d4;
  }
}
@media screen and (max-height:700px){
    
  .custome-calculator-wrapper {
    height:100%;
  }
}
@media screen and (max-width:500px){
    
  .custome-calculator {
    width:100%;
  }
}
</style>